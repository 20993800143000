import '/assets/styles/pages/campaigns.scss';

import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';

// Partials
import Placeholder from '/views/partials/placeholder'
import Img from 'pstv-commerce-tools/partials/img';
// import Link from 'pstv-commerce-tools/partials/link';

// Context
import { useBreakpoints } from 'pstv-commerce-tools/utilities/breakpoints';
import { useGlobalState } from 'pstv-commerce-tools/utilities/global-state';
import { useModals } from 'pstv-commerce-tools/utilities/modals';

// Services
import siteServices from '/services/site';

// Functions
import getCdnImage from '/functions/get-cdn-image'

const getCampaignCdnImage = (image, width, height) => {
	return getCdnImage(image, {
		width: width,
		height: height,
		bgColor: 'white',
		quality: 50,
		mode: 'crop',
	});
}

const getCampaigns = () => {
	return new Promise((resolve, reject) => {
		siteServices.getCampaigns().then(campaigns => {
			resolve(campaigns.map(campaign => (
				{
					...campaign,
					images: {
						web: getCampaignCdnImage(campaign.image, 413, 163),
						web2x: getCampaignCdnImage(campaign.image, 826, 326),
						mobile: getCampaignCdnImage(campaign.image, 678, 268),
					}
				}
			)));
		}).catch(reject);
	});
}

const Campaigns = ({ pageProps }) => {
	const { desktop } = useBreakpoints();
	const { setError } = useGlobalState();
	const { openModal } = useModals();

	const [campaignData, setCampaignData] = useState(pageProps.campaigns ?? null);

	useEffect(() => {
		if(campaignData === null) {
			getCampaigns().then(setCampaignData).catch(() => {
				setError();
			})
		}
	}, [campaignData])

	const campaigns = useMemo(() => {
		return campaignData ?? [false, false, false, false, false, false];
	}, [campaignData]);

	return (
		<div className="campaigns-wrap wrapper">
			<h1 className="section campaigns-title">Kampanyalar</h1>

			<section className="section campaigns-group">
				{/* <h2 className="group-title"><span>Size Özel Kampanyalar</span></h2> */}

				<ul className="group-list">
					{campaigns.map((campaign, nth) => (
						<li className="list-campaign" key={campaign?.id ?? nth}>
							{campaign ?
								<>
									{/* <Link
										href="campaignDetail"
										params={{ id: campaign.id }}
										className="campaign-link">
										<Img
											cover
											className="campaign-image"
											srcSet={desktop ? `${campaign.images.web2x} 2x` : undefined}
											src={desktop ? campaign.images.web : campaign.images.mobile}
											alt={campaign.sort_description} />
									</Link> */}
									<button
										type="button"
										onClick={() => {
											openModal('campaignDetail', { campaign: campaign })
										}}
										className="campaign-link">
										<Img
											cover
											className="campaign-image"
											srcSet={desktop ? `${campaign.images.web2x} 2x` : undefined}
											src={desktop ? campaign.images.web : campaign.images.mobile}
											alt={campaign.sort_description} />
									</button>
								</>
								:
								<Placeholder className="campaign-link" />
							}
						</li>
					))}
				</ul>
			</section>
		</div>
	)
}

Campaigns.propTypes = {
	children: PropTypes.object,
	pageProps: PropTypes.object
}

Campaigns.getServerProps = () => {
	return new Promise((resolve) => {
		Promise.all([
			getCampaigns()
		]).then(([campaigns]) => {
			resolve({
				pageProps: {
					campaigns: campaigns,
				},
			})
		}).catch(() => {
			resolve({
				status: 500,
			})
		})
	})
}

export default Campaigns
